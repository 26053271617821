.card {
  background-color: var(--surface-card);
  padding: 1.5rem;
  margin-bottom: 1rem;
  border-radius: $borderRadius;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05),
    0px 1px 4px rgba(0, 0, 0, 0.08) !important;

  &.card-w-title {
    padding-bottom: 2rem;
  }
}
